import { Grid } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { config } from "../../config";
import opsmileArabicTemplate from "./OpsmileArabicTemplate.html?raw";
import opsmileEnglishTemplate from "./OpsmileEnglishTemplate.html?raw";

import styles from "./Opsmile.module.css";

function generateRandomString() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWX123456YZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Opsmile() {
  const [opSmileData, setOpsmileData] = useState({
    name: "",
    title: "",
    description: "",

    mainArabicImage: "",
    mainArabicTitle: "",
    secondArabicTitle: "",

    mainEnglishImage: "",
    mainEnglishTitle: "",
    secondEnglishTitle: "",

    startDate: "",
    endDate: "",

    specialSlug: generateRandomString(),
  });

  const [englishLPdata, setEnglishLPdata] = useState(null);
  const [arabicLPdata, setArabicLPdata] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const handleClosePopup = () => {
    setPopup(false);
    location.reload();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOpsmileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const { name } = e.target;
    // Update state directly with file object
    setOpsmileData((prevState) => ({
      ...prevState,
      [name]: file,
    }));
  };

  const handleVisitEnglishPage = () => {
    window.open(englishLPdata?.data.link, "_blank");
  };

  const handleVisitArabicPage = () => {
    window.open(arabicLPdata?.data.link, "_blank");
  };

  const apiLink = `${config.appgainServer}/apps/${config.appId}/landingpages`;
  const deleteArabicLink = `${config.appgainServer}/apps/${config.appId}/landingpagesbysulg/${opSmileData.keyword}-ar`;
  const deleteEnglishLink = `${config.appgainServer}/apps/${config.appId}/landingpagesbysulg/${opSmileData.keyword}`;
  const apiKey = config.appApiKey;

  const submitValuesToCreateOpsmile = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!opSmileData.mainArabicImage || !opSmileData.mainEnglishImage) {
      setIsLoading(false);
      // Notify user
      return;
    }

    let uploadedArabicImage;
    let uploadedEnglishImage;
    const formData = new FormData();
    const formDataEnglish = new FormData();
    formData.append("file", opSmileData.mainArabicImage);
    formDataEnglish.append("file", opSmileData.mainEnglishImage);
    try {
      const arabicImageData = await axios.post(
        "https://adminapi.appgain.io/aws-cdn.appgain-uploader?folder=63760620a902ad017e51ca8b&bucket=content.quantatel.com",
        formData,
        {
          headers: {
            "x-api-key": "appgainapikey1024",
          },
        }
      );

      const englishImageData = await axios.post(
        "https://adminapi.appgain.io/aws-cdn.appgain-uploader?folder=63760620a902ad017e51ca8b&bucket=content.quantatel.com",
        formDataEnglish,
        {
          headers: {
            "x-api-key": "appgainapikey1024",
          },
        }
      );

      uploadedArabicImage = `https://cdn.ikhair.net/${arabicImageData.data.path}`;
      uploadedEnglishImage = `https://cdn.ikhair.net/${englishImageData.data.path}`;

      let finalEnglishTemplate = opsmileEnglishTemplate;
      let finalArabicTemplate = opsmileArabicTemplate;

      Object.entries(opSmileData).forEach(([key, value]) => {
        if (key === "mainEnglishImage") {
          finalEnglishTemplate = finalEnglishTemplate.replaceAll(
            `{{${key}}}`,
            uploadedEnglishImage
          );
        } else if (key === "mainArabicImage") {
          finalArabicTemplate = finalArabicTemplate.replaceAll(
            `{{${key}}}`,
            uploadedArabicImage
          );
        } else {
          finalEnglishTemplate = finalEnglishTemplate.replaceAll(
            `{{${key}}}`,
            value
          );
          finalArabicTemplate = finalArabicTemplate.replaceAll(
            `{{${key}}}`,
            value
          );
        }
      });

      const sendingEnglishData = {
        slug: opSmileData.specialSlug,
        name: opSmileData.name,
        label: opSmileData.name,
        lang: "en",
        socialmedia_settings: {
          title: opSmileData.title,
          description: opSmileData.description,
        },
        web_push_subscription: true,
        image_default: true,
        theme: "code_embed",
        FBpixel_tracking: true,
        twitter_pixel_tracking: false,
        google_ads_pixel_tracking: false,
        linkedIn_pixel_tracking: false,
        snapchat_pixel_tracking: false,
        tiktok_pixel_tracking: false,
        show_in_defPage: false,
        components: [
          { type: "basic.h+logo" },
          { type: "basic.p" },
          {
            type: "basic.code_embded_component",
            src: `${finalEnglishTemplate}`,
          },
        ],
      };

      const sendingArabicData = {
        slug: `${opSmileData.specialSlug}-ar`,
        name: opSmileData.name,
        label: opSmileData.name,
        lang: "ar",
        socialmedia_settings: {
          title: opSmileData.title,
          description: opSmileData.description,
        },
        web_push_subscription: true,
        image_default: true,
        theme: "code_embed",
        FBpixel_tracking: true,
        twitter_pixel_tracking: false,
        google_ads_pixel_tracking: false,
        linkedIn_pixel_tracking: false,
        snapchat_pixel_tracking: false,
        tiktok_pixel_tracking: false,
        show_in_defPage: false,
        components: [
          { type: "basic.h+logo" },
          { type: "basic.p" },
          {
            type: "basic.code_embded_component",
            src: `${finalArabicTemplate}`,
          },
        ],
      };

      const promises = [
        axios.delete(deleteArabicLink, {
          headers: {
            "Content-Type": "application/json",
            appApiKey: apiKey,
          },
        }),
        axios.delete(deleteEnglishLink, {
          headers: {
            "Content-Type": "application/json",
            appApiKey: apiKey,
          },
        }),

        axios.post(apiLink, sendingEnglishData, {
          headers: {
            "Content-Type": "application/json",
            appApiKey: apiKey,
          },
        }),
        axios.post(apiLink, sendingArabicData, {
          headers: {
            "Content-Type": "application/json",
            appApiKey: apiKey,
          },
        }),
      ];

      const [english, arabic] = await Promise.all(promises);
      setEnglishLPdata(english);
      setArabicLPdata(arabic);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
      setPopup(true);
    }
  };

  if (isLoading) return <LoadingSpinner />;
  return (
    <div>
      <div className="header">
        <h4 className="title">Create Operation Smile pages</h4>
      </div>
      <form
        className="card"
        style={{ padding: "12px 24px" }}
        onSubmit={submitValuesToCreateOpsmile}
      >
        <Grid container spacing={3} style={{ padding: "15px 28px" }}>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="name" className="control-label">
              Name
            </label>
            <FormControl
              type="text"
              value={opSmileData.name}
              onChange={handleChange}
              name="name"
              id="name"
              placeholder="Enter name"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="title" className="control-label">
              Title
            </label>
            <FormControl
              type="text"
              value={opSmileData.title}
              onChange={handleChange}
              name="title"
              id="title"
              placeholder="Enter title"
              required
              maxLength={31}
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="description" className="control-label">
              Description
            </label>
            <FormControl
              type="text"
              value={opSmileData.description}
              onChange={handleChange}
              name="description"
              id="description"
              placeholder="Enter description"
              required
              maxLength={500}
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="arabicImage" className="control-label">
              Arabic Image Link
            </label>
            <FormControl
              type="file"
              onChange={handleFileChange}
              name="mainArabicImage"
              id="arabicImage"
              placeholder="Enter Arabic image Link"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="mainArabicTitle" className="control-label">
              main Arabic Title
            </label>
            <FormControl
              type="text"
              value={opSmileData.mainArabicTitle}
              onChange={handleChange}
              name="mainArabicTitle"
              id="mainArabicTitle"
              placeholder="Enter main Arabic Title"
              required
              maxLength={500}
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="secondArabicTitle" className="control-label">
              second Arabic Title
            </label>
            <FormControl
              type="text"
              value={opSmileData.secondArabicTitle}
              onChange={handleChange}
              name="secondArabicTitle"
              id="secondArabicTitle"
              placeholder="Enter second Arabic Title"
              required
              maxLength={500}
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="englishImage" className="control-label">
              English Image Link
            </label>
            <FormControl
              type="file"
              onChange={handleFileChange}
              name="mainEnglishImage"
              id="englishImage"
              placeholder="Enter English image Link"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="mainEnglishTitle" className="control-label">
              main English Title
            </label>
            <FormControl
              type="text"
              value={opSmileData.mainEnglishTitle}
              onChange={handleChange}
              name="mainEnglishTitle"
              id="mainEnglishTitle"
              placeholder="Enter main English Title"
              required
              maxLength={500}
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="secondEnglishTitle" className="control-label">
              second English Title
            </label>
            <FormControl
              type="text"
              value={opSmileData.secondEnglishTitle}
              onChange={handleChange}
              name="secondEnglishTitle"
              id="secondEnglishTitle"
              placeholder="Enter second English Title"
              required
              maxLength={500}
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="StartDate" className="control-label">
              Start Date
            </label>
            <FormControl
              type="date"
              value={opSmileData.startDate}
              onChange={handleChange}
              name="startDate"
              id="StartDate"
              placeholder="Enter the Start Date"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="endDate" className="control-label">
              End Date
            </label>
            <FormControl
              type="date"
              value={opSmileData.endDate}
              onChange={handleChange}
              name="endDate"
              id="endDate"
              placeholder="Enter the End Date"
              required
            />
          </Grid>
        </Grid>
        <div
          className="submit"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {popup && (
            <div className={styles.popupOverlay}>
              <div className={styles.popup}>
                <h4>Operation Smile pages Links</h4>
                <p>
                  You're viewing Operation Smile micro-pages links, available in
                  both Arabic and English. Choose whichever you want to visit.
                </p>
                <div className={styles.buttons}>
                  <button type="button" onClick={handleVisitArabicPage}>
                    Arabic Page
                  </button>
                  <button type="button" onClick={handleVisitEnglishPage}>
                    English Page
                  </button>
                  <button type="button" onClick={handleClosePopup}>
                    Close the Popup
                  </button>
                </div>
              </div>
            </div>
          )}
          <Button
            className="create-edit-form-btn"
            type="submit"
            style={{ alignSelf: "flex-end" }}
          >
            Create Landing pages
          </Button>
        </div>
      </form>
    </div>
  );
}
export default Opsmile;
